import {Intl} from "./Intl.d";

const CzechIntl: Intl = {
    homeHeroHeading: "Inovativní biomateriál pro regenerativní medicínu",
    homeHeroHeading1: "Léčte klouby stimulací přirozené tvorby kolagenu",
    homeHeroLead: "Inovativní léčba bolesti a degenerativních změn kloubů",
    primaryActionButtonLabel: "Kde na PEPTYS",
    heroSocialProof: "+200 inovativních klinik používá PEPTYS",
    homeHeading1: "Léčte bolest kloubů stimulací přirozené tvorby kolagenu",
    homeLead1: "Injekční roztok s nízkomolekulárními peptidy zlepšuje strukturu pojivových tkání a redukuje bolesti do 48 hodin.",
    productGuideHeading: "Který PEPTYS je ten pravý",
    productGuideLead: "Zaměřený na různé problémy s pohybovým aparátem, PEPTYS je ideálním řešením pro vaše zdravotní výzvy. Poskytuje účinnou podporu pro klouby, šlachy, svaly a další.",
    testimonialsHeading: "Co říkají lidé o PEPTYSu",
    testimonialsLead: "Přečtěte si recenze od klientů, kteří využili PEPTYS v boji proti bolestem a zraněním. Zjistěte, jak jim tento produkt pomohl s jejich problémy.",
    homePageMetaTitle: "Léčba bolesti a degenerativních změn kloubů",
    homePageMetaDescription: "Účinná léčba kloubů prostřednictvím stimulace vlastní produkce kolagenu. Zjistěte jak mohou nízkomolekulární peptidy pomoci při problémech s klouby.",

    linkEducate: "Jak účinkuje",
    linkBlog: "Blog",
    linkContact: "Kontakt",
    linkForDoctors: "Pro kliniky",
    linkApplication: "Kde na PEPTYS",
    linkResearchPapers: "Klinické studie",
    linkTerms: "Obchodní podmínky",
    linkPrivacyPolicy: "Ochrana soukromí",
    linkLeaflet: "Návod k použití",

    benefit1Title: "Překonejte zranění",
    benefit1Lead: "Nepřestaňte sledovat své fitness cíle. PEPTYS poskytuje cílenou podporu pro rychlejší obnovu a snižuje riziko opětovných zranění.",
    benefit2Title: "Zůstaňte mladí a silní",
    benefit2Lead: "Čelte výzvám stárnutí a udržte si mladistvou vitalitu. PEPTYS pomáhá zpomalovat známky stárnutí a zlepšuje celkovou pohodu.",
    benefit3Title: "Obnovte zdraví kloubů",
    benefit3Lead: "Zmírněte bolest kloubů a získejte zpět svou svobodu pohybu. Obnovte pohyb bez omezení a vraťte se k vašim oblíbeným činnostem.",

    imgAltPain: "Bolest",
    imgAltTimePast: "Degenerace",
    imgAltSyringe: "Injekce",
    imgAltHandHoldingHeart: "Obnova",

    imgAltArrowsDown: "Šipky dolů",

    featurePlanHeading1: "Boj s opotřebením a zraněními",
    featurePlanLead1: "Ať už jste sportovec bojující se sportovními zraněními, nebo osoba cítící stopy času, náročná fyzická aktivita může být výzvou. Svalové napětí, bolesti kloubů a snížená mobilita jsou běžné překážky, které ovlivňují každodenní život a výkon.",
    featurePlanHeading2: "Porozumění omezením těla",
    featurePlanLead2: "Je důležité rozpoznat, jak zranění a stárnutí ovlivňují naše tělo. Porozumění těmto omezením nám pomáhá efektivně je řešit. Degenerace související se stárnutím a sportovní zranění snižují přirozenou schopnost těla opravit se a obnovit.",
    featurePlanHeading3: "Léčivá síla PEPTYS",
    featurePlanLead3: "Tu přichází PEPTYS - revoluční přístup k přirozenému hojení. Tyto peptidy pracují na buněčné úrovni, stimulují tělo, aby se opravilo samo. PEPTYS podporuje tvorbu kolagenu, čím napomáhá opravě tkání, snižuje zánět a urychluje zotavení.",
    featurePlanHeading4: "Dosáhnutí úplného zotavení a vitality",
    featurePlanLead4: "Představte si, že znovu získáte plný rozsah pohybu, sílu a vitalitu. S PEPTYS se to stává realitou. Uživatelé zažívají výrazné zlepšení svého fyzického stavu, těší se z omlazeného těla a aktivního životního stylu, osvobozeného od bolesti a omezené mobility.",

    learnMoreStripeHeading1: "Jak stručně funguje PEPTYS?",
    learnMoreStripeLead1: "PEPTYS obsahuje LW peptidy, což jsou malé částice bílkovin, které cíleně působí na oblasti těla, které potřebují obnovu. Při použití tyto peptidy komunikují vašim buňkám, aby vyráběly více stavebních materiálů, jako je kolagen, který pomáhá při obnově a regeneraci tkání. Představte si je jako posilu pro váš přirozený proces hojení a obnovy, který vám pomůže získat zpět vaši sílu a vitalitu.",
    learnMoreStripeButtonLabel1: "Přečíst si více",
    learnMoreStripeHeading2: "Proč jsou LW peptidy lepší?",
    learnMoreStripeLead2: "Na rozdíl od kolagenových injekcí, které přímo zavádějí kolagen do těla, LW peptidy fungují tak, že podporují přirozenou produkci kolagenu v těle. Toto lze považovat za přirozenější přístup, jelikož využívá a zlepšuje vlastní opravné mechanismy těla. Malá velikost LW peptidů je klíčová, protože umožňuje jejich snadnou absorpci tělem, čím zajišťuje efektivní doručení do cílených oblastí, jako jsou tkáně a klouby.",
    learnMoreStripeButtonLabel2: "Přečíst si více",

    productGuidanceTitle1: "Klouby",
    productGuidanceSyndromes1: ["Osteoartróza", "Meniskus", "Poškozená chrupavka", "Poškozené vazy"],
    productGuidanceTitle2: "Šlachy",
    productGuidanceSyndromes2: ["Skokanské koleno", "Achillova šlacha", "Tenisový loket", "Skákavý prst"],
    productGuidanceTitle3: "Páteř",
    productGuidanceSyndromes3: ["Bolesti", "Omezený pohyb"],
    productGuidanceTitle4: "Malé klouby",
    productGuidanceSyndromes4: ["Skokanské koleno", "Achillova šlacha", "Tenisový loket", "Skákavý prst"],
    productGuidanceTitle5: "Sval",
    productGuidanceSyndromes5: ["Natažený sval", "Natrhnutý sval"],

    testimonialsQuote1: "Dlouhodobé bolesti zápěstí mi bránily v cvičení. Po ošetření jsem pocítil úlevu do 24 hodin. Nyní cvičím bez omezení.",
    testimonialsAuthor1: "Maroš Z.",
    testimonialsQuote2: "Trpěla jsem osteoartrózou a meniskem v koleni, bolesti mi bránily ve spánku a závisela jsem na analgetikách. Už po 48 hodinách od ošetření jsem pocítila výraznou úlevu a nyní, půl roku později, jsem absolvovala preventivní aplikaci, aby se bolesti nevrátily.",
    testimonialsAuthor2: "Božena N.",
    testimonialsQuote3: "S diagnózou pokročilé koxartrózy bederního kloubu jsem čelil bolestem hlavně v noci a lékař mi doporučil výměnu kloubu. Ošetření mi přineslo pokles intenzity bolesti do 8 dní, a nyní již nepotřebuji analgetika a mohu se volně pohybovat.",
    testimonialsAuthor3: "Jaroslav O.",
    testimonialsQuote4: "Bolesti krční páteře mi značně komplikovaly studium. PEPTYS mi přinesl úlevu už do 24 hodin, nyní mám uvolněný krk a mohu se věnovat studiu bez bolestí.",
    testimonialsAuthor4: "Natálie M.",
    testimonialsQuote5: "PEPTYS jsem si vybral po sutuře MM menisku a zjištěné chondropatii II-III. I po 5 měsících od operace přetrvával výpotek a ztuhlost, ale po aplikaci se stav zlepšil již po 24 hodinách. Po 48 hodinách bylo znatelné zlepšení hybnosti a aktuálně 4. den není žádná bolest ani otok po třech cvičeních. PEPTYS hodnotím velmi pozitivně a rozhodl jsem se pro aplikaci i do druhého kolena.",
    testimonialsAuthor5: "Mrg. Tibor B.",
    testimonialsQuote6: "Aktivně celý život sportuji. Zdravý život je moje priorita! Avšak zraněním se nedalo vyhnout. Vyzkoušel jsem PEPTYS a mohu prohlásit, že část mých zdravotních problémů se s ním dala vyřešit. A řeším je nadále....",
    testimonialsAuthor6: "Igor I.",
    testimonialsQuote7: "Jako bývalý reprezentant Slovenské republiky ve sportovní gymnastice a stále aktivní sportovec jsem začal pociťovat opotřebení kloubů, ale po aplikaci PEPTYSu jsem pocítil úlevu od mých problémů.",
    testimonialsAuthor7: "Eduard M.",

    agePlural: "let",

    nextStepsHeading: "Další kroky",
    nextStepsForDoctorsHeading: "Pro lékaře",
    nextStepsForDoctorsLead: "Zaujal vás PEPTYS? Zanechte nám své kontaktní údaje a my vás budeme kontaktovat s nabídkou na míru.",
    nextStepsLearnMoreHeading: "Zjistit více",
    nextStepsLearnMoreLead: "Přečtěte si více o tom, jak může PEPTYS pozitivně ovlivnit vaše zdraví a celkovou pohodu.",
    nextStepsApplicationHeading: "Kde na PEPTYS",
    nextStepsApplicationLead: "Objevte na interaktivní mapě nejbližší místo, kde si můžete nechat vpíchnout PEPTYS.",
    imgAltOpen: "Otevřít",

    footerText: "PEPTYS je inovativní zdravotnická pomůcka účinná při bolestech a degenerativních změnách kloubů.",
    footerActionButtonLabel: "Kde na PEPTYS",
    footerCol1Title: "OFFICE",
    footerCol2Title: "ODKAZY",
    footerCol3Title: "ODKAZY",

    articleNotFoundHeading: "Článek nebyl nalezen",
    articleBackButton: "Zpět na blog",

    blogHeading: "Nejnovější články",
    blogEmptyMessage: "Články se nepodařilo načíst",
    blogMetaTitle: "Blog",
    blogMetaDescription: "Přečtěte si o inovativních řešeních pro léčbu bolesti a degenerativních změn kloubů na našem blogu. Odborné články o přípravku PEPTYS, nejnovějších terapiích a skutečných příbězích úspěchu.",

    imgAltError: "Chyba",

    applicationHeading: "Kde na PEPTYS",
    applicationLead: "Přinášíme vám seznam míst, kde Vám ochotně píchnou peptidovou injekci PEPTYS. Zavolejte na uvedené telefonní číslo a naši obchodní zástupci Vám posunou kontakt na lékaře a kliniku, kde injekci PEPTYS dostanete.",
    mapString: "Mapa",
    applicationPageMetaTitle: "Kde na PEPTYS",
    applicationPageMetaDescription: "Objevte na interaktivní mapě nejbližší místo, kde si můžete nechat vpíchnout PEPTYS.",

    currencySymbol: "CZK",

    locationString: "Lokace",
    phoneString: "tel. č.",
    emailString: "e-mail",
    salesRepresentativeString: "Obchodní zástupce",
    salesRepresentativesHeading: "Zastoupení společnosti pro ČR",
    salesRepresentativesLead: "Spojte se s našimi obchodními zástupci, kteří jsou připraveni poskytnout vám podrobné informace o PEPTYSu a pomoci vám s obchodními potřebami.",
    contactOfficeHeading: "Office",
    contactOfficeLead: "Máte otázky nebo potřebujete podporu? Náš tým je tu, aby vám pomohl. Kontaktujte nás pro jakékoliv informace týkající se PEPTYS, objednávek, nebo spolupráce.",
    contactString: "Kontakt",
    addressString: "Adresa",
    contactPageMetaTitle: "Kontakt",
    contactPageMetaDescription: "Spojte se s našimi obchodními zástupci, kteří jsou připraveni poskytnout vám podrobné informace o PEPTYSu a pomoci vám s obchodními potřebami.",

    educateFAQ: [
        {
            question: "Co přesně je PEPTYS?",
            answer: "PEPTYS je terapeutický přípravek obsahující bioaktivní nízkomolekulární peptidy (LWP), specificky navržený pro modulaci regenerativních procesů v tkáních. Tyto peptidy jsou optimalizovány pro maximální biologickou dostupnost a cílenou tkáňovou regeneraci."
        },
        {
            question: "Jak fungují LW peptidy v PEPTYSu?",
            answer: "LW peptidy v PEPTYSu fungují jako bioregulátory, aktivující endogenní regenerační mechanismy těla. Stimulují syntézu kolagenu a elastinu, čím podporují obnovu a zdraví pojivových tkání. Jejich malá molekulová hmotnost zajišťuje efektivní penetraci a biologickou aktivitu v místě aplikace."
        },
        {
            question: "Jaký je rozdíl mezi LW peptidy a kolagenem?",
            answer: "Zatímco kolagen slouží jako hlavní strukturální protein v tkáních, LW peptidy hrají roli signálních molekul, které regulují syntézu kolagenu a dalších matrixových komponent. LW peptidy tedy neposkytují přímou strukturální podporu, ale aktivují intracelulární signalizační dráhy, vedoucí k anabolickým procesům v tkáních."
        },
        {
            question: "Co musím vědět před použitím PEPTYSu?",
            answer: "Před aplikací PEPTYSu je nezbytné absolvovat klinické vyšetření a konzultaci s odborníkem, aby se posoudila vhodnost a bezpečnost léčby v kontextu konkrétní klinické situace pacienta. Důkladné porozumění interakcím s existujícími léčebnými režimy a zdravotním stavem pacienta je také klíčové."
        },
        {
            question: "Jsou nějaké vedlejší účinky spojené s používáním PEPTYSu?",
            answer: "PEPTYS je obecně dobře tolerován, avšak jako u jakékoliv biologicky aktivní látky, mohou nastat lokální reakce v místě aplikace. Výskyt systémových reakcí je minimální, ale při výskytu atypických symptomů se doporučuje okamžitá konzultace se zdravotnickým odborníkem."
        },
        {
            question: "Jak je zajištěna kvalita a bezpečnost PEPTYSu?",
            answer: "Kvalita a bezpečnost PEPTYSu jsou zaručeny přísným dodržováním farmaceutických a biotechnologických výrobních norem, včetně Good Manufacturing Practice (GMP). Výrobek podléhá neustálým kontrolám a validacím, aby se zajistila jeho konzistence, čistota a biologická efektivita."
        },
        {
            question: "Existují nějaká omezení nebo doporučení pro dávkování PEPTYSu?",
            answer: "Optimální dávkování PEPTYSu je stanoveno na základě individuální klinické indikace a tolerance pacienta. Lékařská indikace a monitorování reakce pacienta na terapii jsou klíčové pro určení správné dávky a frekvence aplikace. Vždy se doporučuje vést léčbu pod dohledem zkušeného zdravotnického odborníka."
        }
    ],

    educateVideoHeading: "Zjistěte více o léčbě pohybového aparátu",
    educateVideoLead: "Injekční roztok s nízkomolekulárními peptidy stimuluje tvorbu přirozeného kolagenu a redukuje bolesti do 48 hodin.",

    educateResearchPapersHeading: "Přečtěte si klinické studie",
    educateResearchPapersLead: "Prohlédněte si průběžně aktualizovanou kolekci klinických studií o PEPTYSu.",
    educateResearchPapersButtonLabel: "Klinické studie",

    educatePageMetaTitle: "Zdravé klouby, snížení bolesti a posílení chrupavky díky PEPTYSu",
    educatePageMetaDescription: "Zjistěte, jak PEPTYS přispívá k zdravým kloubům prostřednictvím snížení zánětu a bolesti, udržování chrupavky a stimulace tvorby přirozeného kolagenu.",

    educatePageHeroHeading: "Díky čemu PEPTYS spouští přirozenou obnovu?",
    educatePageHeroLead: "Přečtěte si jak PEPTYS účinkuje na buněčné úrovni a co je za tím, díky čemu vám může pomoci.",
    educatePagePrimaryActionButtonLabel: "Dozvědět se více",

    explanationContainer1Heading: "Začíná to snížením zánětu a bolesti",
    explanationContainer1Title1: "Snížení zánětu a bolesti",
    explanationContainer1Lead1: "LWP v PEPTYS se zapojují do složitých interakcí v rámci buněčných signalizačních sítí. Specificky se zaměřují na zánětlivé dráhy vázáním na receptory a inhibicí enzymů, které zánět spouštějí. Modulací těchto buněčných aktivit pomáhají LWP obnovit vyváženou imunitní odpověď, která je rozhodující pro snížení chronického zánětu.",
    explanationContainer1Title2: "Inhibice degradace tkání",
    explanationContainer1Lead2: "Lytické enzymy jako matricové metaloproteinázy (MMP) hrají klíčovou úlohu při degradaci tkáně. LWP inhibují nadměrnou aktivitu těchto enzymů, omezují nadměrné odbourávání složek extracelulární matrix, čím snižují zánět a související bolest.",
    explanationContainer1ImgAlt: "Bolest kolena",

    explanationContainer2Heading: "Pokračuje posílením a regenerací tkání",
    explanationContainer2Title1: "Stimulace tvorby kolagenu",
    explanationContainer2Lead1: "LWP stimulují fibroblasty a chondrocyty na zvýšení syntézy kolagenu, hlavní složky chrupavky a pojivových tkání. Tato selektivní stimulace je životně důležitá pro udržení integrity tkáně a usnadnění procesu hojení.",
    explanationContainer2Title2: "Podpora regenerace tkání",
    explanationContainer2Lead2: "Reparace tkáně obvykle vede k tvorbě jizev, které jsou funkčně horší než původní tkáň. LWP posouvají reakci těla směrem k regeneraci, čím podporují tvorbu tkání, které se strukturou a funkcí více podobají jejich původnímu stavu.",
    explanationContainer2ImgAlt: "Přirozená produkce kolagenu",

    explanationContainer3Heading: "A končí zlepšenou mobilitou",
    explanationContainer3Title1: "Udržování zdraví chrupavky",
    explanationContainer3Lead1: "Chrupavka, tlumící tkáň v kloubech, se časem opotřebovává. LW peptidy pomáhají udržovat zdraví chrupavky tím, že podporují syntézu jejích základních složek a zabraňují jejímu rozpadu, čím zachovávají funkčnost kloubů.",
    explanationContainer3Title2: "Zvýšená pohyblivost kloubů díky zdravé chrupavce",
    explanationContainer3Lead2: "Zlepšené zdraví chrupavky se přímo promítá do zlepšené pohyblivosti kloubů. Klouby se stávají pružnější a méně bolestivé, což umožňuje plynulejší pohyb a lepší rozsah pohybu.",
    explanationContainer3ImgAlt: "Svoboda pohybu",

    explanationMAPHeading: "Klíčová role složky",
    explanationMAPString: "Magnesium Ascorbyl Fosfát (MAP)",
    explanationMAPTitle1: "Biochemická role",
    explanationMAPLead1: "Magnesium Ascorbyl Fosfát (MAP) v PEPTYS hraje mnohostrannou roli. Podílí se na syntéze a stabilizaci peptidů, zatímco jeho antioxidační vlastnosti chrání tkáně před oxidačním stresem, klíčovým faktorem stárnutí a zánětu.",
    explanationMAPTitle2: "Synergie s LWP",
    explanationMAPLead2: "Začlenění MAP zvyšuje stabilitu a biologickou dostupnost LWP, díky čemuž je kombinace účinnější než každá ze složek samostatně. Tato synergie vede k lepšímu terapeutickému účinku, maximalizující regenerační potenciál PEPTYSu.",

    leadPageLeadText: "JSTE LÉKAŘ NEBO KLINIKA?",
    leadPageHeadingText: "Zjistěte jak může PEPTYS pomoci vašim pacientům",
    leadPageStepsTitle: "Co můžete očekávat?",
    leadPageStep1: "Strategický telefonát, který nám pomůže pochopit váš případ",
    leadPageStep2: "Představíme jedinečné vlastnosti PEPTYSu",
    leadPageStep3: "Podpora při integraci PEPTYSu do vašeho terapeutického režimu",
    leadPageThankYouString1: "Děkujeme za vaši žádost.",
    leadPageThankYouString2: "Naši obchodní zástupci se vám ozvou co nejdříve.",
    leadPageBackButtonLabel: "Zpět na úvodní stránku",

    leadPageFormName: "Jméno a příjmení",
    leadPageFormEmail: "Pracovní email",
    leadPageFormPhone: "Telefonní číslo",
    leadPageFormClinicName: "Název kliniky",
    leadPageFormCity: "Město",
    leadPageFormTerms1: "Při odeslání mých osobních údajů rozumím a souhlasím s jejich shromažďováním a zpracováváním v souladu s",
    leadPageFormTerms2: "podmínkami a pravidly LAMOMEDIC, s.r.o.",
    leadPageFormSubscribe: "Zasílejte mi novinky o PEPTYSu",
    leadPageFormButtonLabel: "Kontaktujte mě",
    leadPageSocialProof: "+200 inovativních klinik používá PEPTYS",
    leadPageMetaTitle: "Kontakt pro lékaře a kliniky",
    leadPageMetaDescription: "Otevřete dveře novým možnostem v léčbě kloubů. Připojte se k PEPTYS a dejte svým pacientům přístup k nejmodernější léčbě kloubů.",

    notFoundPageError: "Chyba 404",
    notFoundPageHeading: "Stránka se nenašla",
    notFoundPageLead: "Vypadá to, že stránka, kterou hledáte, neexistuje.",
    notFoundPageBackButtonLabel: "Zpět",
    notFoundPageHomeButtonLabel: "Domů",
    notFoundPageMetaTitle: "Stránka se nenašla",

    errorString: "Chyba",

    companyString: "Společnost"
}

export default CzechIntl;